<template>
  <header class=" text-center">
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container-fluid">
        <div class="nav-flex">
          <div class="brand-name">
            <router-link to="/">
              <img src="../assets/kiwoa_logo.jpg" />
            </router-link>
          </div>

          <div class="nav-list-two d-flex align-items-center justify-content-center">
              <div class="menu">
                <div @click="openNav()">
                  <div>
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                  </div>
                </div>
              </div>
              <div class="showDesktopOnly">
                <router-link 
                  to="/"
                >
                  <span 
                    class="mr-4 list-one" 
                  :class="{ active: $route.name == 'Home' }"
                    style="cursor: pointer"
                    >Home</span
                  >
                </router-link>
                <router-link to="/about-us">
                  <span 
                    class="mr-4 list-one" 
                    :class="{ active: $route.name == 'About' }" 
                    style="cursor: pointer"
                    >About Us</span
                  >
                </router-link>
                <router-link 
                  to="/our-products"
                >
                  <span 
                    class="mr-4 list-one" 
                  :class="{ active: $route.name == 'Products' }"
                    style="cursor: pointer"
                    >Our Products</span
                  >
                </router-link>
                <router-link to="/children">
                  <span 
                    class="mr-4 list-one"
                    :class="{ active: $route.name == 'Children' }" 
                    style="cursor: pointer"
                    >Children</span
                  >
                </router-link>
                <router-link to="/women">
                  <span 
                    class="mr-4 list-one"
                    :class="{ active: $route.name == 'Women' }" 
                    style="cursor: pointer"
                    >Women</span
                  >
                </router-link>
                <!-- <router-link to="/">
                  <span 
                    class="mr-4 list-one" 
                    :class="{ active: $route.name == 'Dining' }" 
                    style="cursor: pointer"
                    >Upcoming Events</span
                  >
                </router-link> -->
                <span class="mr-4">
                  <el-button size="medium" class="bookBtn" @click="$router.push({ path: '/donate' })">
                    Donate
                  </el-button>
                </span>
              </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="">
      <el-dialog title="Outer Dialog" :visible.sync="outerVisible" center>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
    <div class="sidebar">
      <ul>
        <li @click="closeNav()">
          <router-link to="/">
            <span class="mr-4" style="cursor: pointer">Home</span>
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link to="/about-us">
            <span class="mr-4" style="cursor: pointer">About Us</span>
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link to="/our-products">
            <span class="mr-4" style="cursor: pointer">Our Products</span>
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link to="/children">
            <span class="mr-4" style="cursor: pointer">Children</span>
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link to="/women">
            <span class="" style="cursor: pointer"
              >Women</span
            >
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link to="/donate">
            <span class="mr-4" style="cursor: pointer"
              >Donate</span
            >
          </router-link>
        </li>
      </ul>
    </div>
    </el-drawer>
  </header>
</template>

<script>
  // import SidebarComponent from './sidebar-component.vue';
  export default {
    // components: {SidebarComponent},
    data() {
      return {
        drawer: false,
        sidebarDrawer: false,
        outerVisible: false,
        innerVisible: false,
      };
    },

    methods: {
      openNav() {
        this.sidebarDrawer = true;
      },

      closeNav() {
        this.sidebarDrawer = false;
      },
    },
  };
</script>

<style scoped>
nav {
  background-color: #9EAB37;
  padding-top: 10px;
  padding-bottom: 10px;
  /* #775741 #774525*/
}
nav a {
  color: #FFF5FF;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: none;
}
a .active {
  border-bottom: 1px solid #e5ecb1;
  color: #e5ecb1;
  font-weight: 600;
}

a:hover span{
  border-bottom: 1px solid #e5ecb1;
  transition: 0.5s;
}


 
.side-link {
  color: var(--el-app-primary);
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.list-one {
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex .nav-list-two {
  list-style: none;
  height: 100%;
  padding: 0px;
}

.nav-flex span {
  font-size: 0.7em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex span:hover {
  color: #e5ecb1;
  transition: 0.5s;
}

.brand-name {
  width: 100px !important;
  padding: 0px;
  margin: 50px 0px 0px 0px !important;
  /* margin: 0px auto; */
}

.brand-name img {
  width: 100px;
  padding: 0px;
  margin: 0px;
}

.bookBtn {
  border: 1px solid #9EAB37;
  border-radius: 0px;
  background-color: #775741;
  color: white;
  transition: 0.5s;
}

.bookBtn:hover {
  color: white;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: #fbf2e1;
  margin: 5px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.showDesktopOnly {
  display: block;
}
.showMobileOnly {
  display: none;
}

.sidebar {
  background-color: #3D290A;
  height: 100%;
  padding: 50px 20px;
}

.sidebar ul{ 
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
 }

.sidebar ul li { 
  border-bottom: 1px solid #684613;
  padding-top: 30px;
 }

 .sidebar ul li a {
  color: #FBF0E0;
 }

 .sidebar ul li a:hover {
  color: #fdfdfd;
 }


/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .menu {
    display: block;
  }
  .list-one {
    display: none;
  }
  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .menu {
    display: block;
  }
  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
  .list-one {
    display: none;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }
  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
  .list-one {
    display: none;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }
  .list-one {
    display: none;
  }
  .showDesktopOnly {
    display: none;
  }
  .showMobileOnly {
    display: block;
  }
}
</style>

<style>
  el-drawer {
    background-color: red;
  }
</style>