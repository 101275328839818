import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,

    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: '/about-us',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/payment',
        name: 'Payment',
        // route level code-splitting
        // this generates a separate chunk (payment.[hash].js) for this route
        // which is lazy-loaded when the route is visited.payment" */ '../views/Payment.vue')
      },
      {
        path: '/contact-us',
        name: 'ContactUs',
        // route level code-splitting
        // this generates a separate chunk (contact-us.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/Contact-us.vue')
      },
      {
        path: '/gallery',
        name: 'Gallery',
        // route level code-splitting
        // this generates a separate chunk (gallery.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
      },
      {
        path: '/our-people',
        name: 'OurPeople',
        // route level code-splitting
        // this generates a separate chunk (our-people.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "our-people" */ '../views/our-people.vue')
      },
      {
        path: '/recycling-straws',
        name: 'RecyclingStraws',
        component: () => import(/* webpackChunkName: "recycling-straws" */ '../views/recycling-straws.vue')
      },
      {
        path: '/women',
        name: 'Women',
        component: () => import(/* webpackChunkName: "women" */ '../views/women.vue')
      },
      {
        path: '/children',
        name: 'Children',
        component: () => import(/* webpackChunkName: "children" */ '../views/children.vue')
      },
      {
        path: '/donate',
        name: 'Donate',
        component: () => import(/* webpackChunkName: "donate" */ '../views/donate.vue')
      },
      {
        path: '/our-products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/products.vue')
      }

    ]
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
