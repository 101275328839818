<template>
  <footer>
    <article>
      <section>
        <div class="pt-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <small style="font-weight: 600">CONTACT INFO</small><br />
                <ul>
                  <li>
                    <i class="fas fa-map-marker-alt"></i> Mbuya 1 Parish Kaggo Zone,
                    Nakawa Division, P.O Box 22145,
                    Kampala-Uganda,
                  </li>
                </ul>
                <ul>
                  <li><i class="fab fa-whatsapp"></i> : +256 787 545848</li>
                  <li>
                    <i class="fas fa-phone-volume"></i> : +256 (0) 414221332
                  </li>
                  <li>
                    <i class="fas fa-phone-volume"></i> : +256 (0) 705119948
                  </li>

                  <li>
                    <a href="mailto:bennynanyonga@gmail.com">
                      <i class="fas fa-envelope"></i> :
                      bennynanyonga@gmail.com
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <p style="font-weight: 600">Products</p>
                    <ul>
                      <li @click="$router.push({path: '/recycling-straws'})">
                        <small>Recycling Straws</small>
                      </li>
                      <li @click="$router.push({path: '/our-products'})">
                        <small>Our Products</small>
                      </li>
                      <!-- <li @click="$router.push({path: '/return-policy'})">
                        <small>Report a product</small>
                      </li> -->
                      <li>
                        <a href="https://wa.me/+256 787 545848/?text=Hello, Kinawataka Women Initiative" target="_blank">
                          Help Center
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <small style="font-weight: 600">ABOUT KIWOI</small
                    ><br />
                    <ul>
                      <li @click="$router.push({path: '/about-us'})">
                        <small>About us.</small>
                      </li>
                      <!-- <li>
                        <small>EG Careers.</small>
                      </li> -->
                      <!-- <li>
                        <small>EG Express.</small>
                      </li> -->
                      <li @click="$router.push({path: '/children'})">
                        <small>Children.</small>
                      </li>
                      <li @click="$router.push({path: '/women'})">
                        <small>Women.</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>

    <section>
       <a href="https://wa.me/+256 787 545848/?text=Hello, Kinawataka Women Association" class="float" target="_blank">
          <!--<i class="fa fa-whatsapp my-float"></i>-->
          <i class="fab fa-whatsapp my-float"></i>
        </a>
    </section>
    <div class="container text-center" style="margin-top: 50px">
      <small
        >copyright &copy; 2022 Kinawataka Women Association | All Rights Reserved</small
      >
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ['1']
    };
  },

  methods: {
      handleChange(val) {
        console.log(val);
      }
  },
};
</script>

<style scoped>
footer {
  background-color: #68442f;
  color: grey;
  padding-top: 50px;
  padding-bottom: 50px;
}

footer ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-flex {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex div {
  font-size: 0.9em;
  font-weight: 400;
}

.brand-name {
  margin-left: 50px;
  margin-right: 50px;
}
.col-title {
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
}

.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .nav-flex {
    display: block;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}
</style>

<style>
  footer .el-collapse-item__header {
    background-color: #3D290A;
    border-bottom: 1px solid #EBEEF5;
    color: #FEF9F6;
    text-align: left;
  }
  footer .el-collapse-item {
    background-color: #3D290A;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__content {
    border: none;
    background-color: #3D290A;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__wrap {
      will-change: height;
      background-color: #3D290A;
      overflow: hidden;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEEF5;
  }
  
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}
  footer {
    text-align: left;
    background-color: #ec9694;
  }
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
  }
  .payments_methods > img {
    display: flex;
    align-items: flex-start;
    background-color: #f8e2e2;
    padding: 2px 0px;
    width: 88px;
    height: 22px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
footer ul li {
  display: block;
  color: #f8f7f7;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8em;
  margin: 0.7em 0;
    list-style-type: none;
    cursor: pointer;
}
footer ul li a {
  color: #f8f7f7;
  font-size: 13px;
  display: block;
  padding: 5px 0px;
  text-decoration: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
footer ul li a:hover {
  color: white;
}

/*##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) { }

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .payments_methods {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>